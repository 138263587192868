import React from "react"
import { Link, graphql } from "gatsby";

import Layout from "../components/layout"

import { rhythm } from "../utils/typography"


const YearArchiveTemplate = ({data, pageContext, location}) => {
    const posts = data.allMarkdownRemark.edges;
    const year = pageContext.year
    const filteredPosts = filterByYear(posts, year);
    return(
        <Layout location={location} data={filteredPosts}>
        </Layout>
    )
}

function filterByYear(data, year)
{
  return data.map((post) => {
    console.log(post);
    var date = post.node.frontmatter.date
    var splstr = date.split(" ")
    if (year === splstr[2])
    {
      return post;
    }
  }).filter(function (elim){
    return elim != null;
  })
}

export default YearArchiveTemplate

export const pagequery = graphql`
query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          html
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`